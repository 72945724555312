import Api from '@/services/Index';

const getStudentInfo = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/thesis/and/projects/form');
}

const getAdvisors = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/thesis/and/projects/advisors', config);
}

const update = async (formId, config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/thesis/and/projects/' + formId, config);
}

const store = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/thesis/and/projects', data);
}

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/thesis/and/projects', config);
}

const show = async (Id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/thesis/and/projects/' + Id);
}

const me = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/thesis/and/projects/me');
}

const getAdvisorApplications = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/thesis/and/projects/advisor', config);
}

const getCoordinatorApplications = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/thesis/and/projects/coordinator', config);
}

export default {
    getStudentInfo,
    getAdvisors,
    update,
    store,
    getAll,
    show,
    me,
    getAdvisorApplications,
    getCoordinatorApplications
}
